import { isEuVersion } from 'constants/env'

import iconFlagUS from 'images/icons/flags/us.svg'
import iconFlagRU from 'images/icons/flags/ru.svg'
import iconFlagES from 'images/icons/flags/es.svg'
import iconFlagPT from 'images/icons/flags/pt.svg'
import iconFlagFR from 'images/icons/flags/fr.svg'
import iconFlagDE from 'images/icons/flags/de.svg'
import iconFlagIT from 'images/icons/flags/it.svg'
import iconFlagNL from 'images/icons/flags/nl.svg'
import iconFlagPL from 'images/icons/flags/pl.svg'
import iconFlagID from 'images/icons/flags/id.svg'
import iconFlagTR from 'images/icons/flags/tr.svg'
import iconFlagKO from 'images/icons/flags/ko.svg'
import iconFlagNO from 'images/icons/flags/no.svg'
import iconFlagSV from 'images/icons/flags/sv.svg'
import iconFlagJP from 'images/icons/flags/jp.svg'
import iconFlagFI from 'images/icons/flags/fi.svg'
import iconFlagLV from 'images/icons/flags/lv.svg'
import iconFlagDA from 'images/icons/flags/da.svg'
import iconFlagRO from 'images/icons/flags/ro.svg'
import iconFlagUK from 'images/icons/flags/uk.svg'

const LOCALES = {
    EN: 'EN',
    RU: 'RU',
    UK: 'UK',
    ES: 'ES',
    PT: 'PT',
    FR: 'FR',
    DE: 'DE',
    IT: 'IT',
    NL: 'NL',
    PL: 'PL',
    ID: 'ID',
    TR: 'TR',
    KO: 'KO',
    NO: 'NO',
    SV: 'SV',
    JP: 'JP',
    FI: 'FI',
    LV: 'LV',
    DA: 'DA',
    RO: 'RO',
}

Object.freeze(LOCALES)

export const LOCALES_DETAILS = {
    [LOCALES.EN]: {
        code: LOCALES.EN,
        deeplSourceCode: 'en',
        deeplTargetCode: 'en-us',
        isRequired: true,
        icon: iconFlagUS,
        label: 'English',
        engLabel: 'English',
        isSupported: true,
    },
    [LOCALES.RU]: {
        code: LOCALES.RU,
        deeplSourceCode: 'ru',
        deeplTargetCode: 'ru',
        isRequired: true,
        icon: iconFlagRU,
        label: 'Русский',
        engLabel: 'Russian',
        isSupported: true,
    },
    [LOCALES.UK]: {
        code: LOCALES.UK,
        deeplSourceCode: 'uk',
        deeplTargetCode: 'uk',
        isRequired: false,
        icon: iconFlagUK,
        label: 'Українська',
        engLabel: 'Ukrainian',
        isSupported: true,
    },
    [LOCALES.ES]: {
        code: LOCALES.ES,
        deeplSourceCode: 'es',
        deeplTargetCode: 'es',
        isRequired: false,
        icon: iconFlagES,
        label: 'Español',
        engLabel: 'Spanish',
        isSupported: true,
    },
    [LOCALES.PT]: {
        code: LOCALES.PT,
        deeplSourceCode: 'pt',
        deeplTargetCode: 'pt-pt',
        isRequired: false,
        icon: iconFlagPT,
        label: 'Português',
        engLabel: 'Portuguese',
        isSupported: true,
    },
    [LOCALES.FR]: {
        code: LOCALES.FR,
        deeplSourceCode: 'fr',
        deeplTargetCode: 'fr',
        isRequired: false,
        icon: iconFlagFR,
        label: 'Français',
        engLabel: 'French',
        isSupported: true,
    },
    [LOCALES.DE]: {
        code: LOCALES.DE,
        deeplSourceCode: 'de',
        deeplTargetCode: 'de',
        isRequired: false,
        icon: iconFlagDE,
        label: 'Deutsch',
        engLabel: 'German',
        isSupported: true,
    },
    [LOCALES.IT]: {
        code: LOCALES.IT,
        deeplSourceCode: 'it',
        deeplTargetCode: 'it',
        isRequired: false,
        icon: iconFlagIT,
        label: 'Italiano',
        engLabel: 'Italian',
        isSupported: true,
    },
    [LOCALES.NL]: {
        code: LOCALES.NL,
        deeplSourceCode: 'nl',
        deeplTargetCode: 'nl',
        isRequired: false,
        icon: iconFlagNL,
        label: 'Dutch',
        engLabel: 'Dutch',
        isSupported: true,
    },
    [LOCALES.PL]: {
        code: LOCALES.PL,
        deeplSourceCode: 'pl',
        deeplTargetCode: 'pl',
        isRequired: false,
        icon: iconFlagPL,
        label: 'Polski',
        engLabel: 'Polish',
        isSupported: true,
    },
    [LOCALES.LV]: {
        code: LOCALES.LV,
        deeplSourceCode: 'lv',
        deeplTargetCode: 'lv',
        isRequired: false,
        icon: iconFlagLV,
        label: 'Latviešu',
        engLabel: 'Latvian',
        isSupported: true,
    },
    [LOCALES.ID]: {
        code: LOCALES.ID,
        deeplSourceCode: 'id',
        deeplTargetCode: 'id',
        isRequired: false,
        icon: iconFlagID,
        label: 'Indonesia',
        engLabel: 'Indonesian',
        isSupported: !isEuVersion,
    },
    [LOCALES.NO]: {
        code: LOCALES.NO,
        deeplSourceCode: 'nb',
        deeplTargetCode: 'nb',
        isRequired: false,
        icon: iconFlagNO,
        label: 'Norsk',
        engLabel: 'Norwegian',
        isSupported: true,
    },
    [LOCALES.SV]: {
        code: LOCALES.SV,
        deeplSourceCode: 'sv',
        deeplTargetCode: 'sv',
        isRequired: false,
        icon: iconFlagSV,
        label: 'Svenska',
        engLabel: 'Swedish',
        isSupported: true,
    },
    [LOCALES.DA]: {
        code: LOCALES.DA,
        deeplSourceCode: 'da',
        deeplTargetCode: 'da',
        isRequired: false,
        icon: iconFlagDA,
        label: 'Dansk',
        engLabel: 'Danish',
        isSupported: true,
    },
    [LOCALES.FI]: {
        code: LOCALES.FI,
        deeplSourceCode: 'fi',
        deeplTargetCode: 'fi',
        isRequired: false,
        icon: iconFlagFI,
        label: 'Suomi',
        engLabel: 'Finnish',
        isSupported: true,
    },
    [LOCALES.TR]: {
        code: LOCALES.TR,
        deeplSourceCode: 'tr',
        deeplTargetCode: 'tr',
        isRequired: false,
        icon: iconFlagTR,
        label: 'Türkçe',
        engLabel: 'Turkish',
        isSupported: true,
    },
    [LOCALES.KO]: {
        code: LOCALES.KO,
        deeplSourceCode: 'ko',
        deeplTargetCode: 'ko',
        isRequired: false,
        icon: iconFlagKO,
        label: '한국어',
        engLabel: 'Korean',
        isSupported: !isEuVersion,
    },
    [LOCALES.JP]: {
        code: LOCALES.JP,
        deeplSourceCode: 'ja',
        deeplTargetCode: 'ja',
        isRequired: false,
        icon: iconFlagJP,
        label: '日本語',
        engLabel: 'Japanese',
        isSupported: !isEuVersion,
    },
    [LOCALES.RO]: {
        code: LOCALES.RO,
        deeplSourceCode: 'ro',
        deeplTargetCode: 'ro',
        isRequired: false,
        icon: iconFlagRO,
        label: 'Română',
        engLabel: 'Romanian',
        isSupported: true,
    },
}

export const getLocaleDetailsByCode = code => LOCALES_DETAILS[code]

export const DEFAULT_LOCALE = LOCALES.EN

export default LOCALES
